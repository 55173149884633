import angular from 'angular'
import { NotificationsData } from './services/data'
import { NotificationsModel } from './services/model'
import { ptNotificationModule } from './components/pt-notification'
import { ptNotificationRecordRetirementOverdue } from './components/pt-notification-record-retirement-overdue/component'
import { ptNotificationAccountRecordsRetirementOverdue } from './components/pt-notification-account-records-retirement-overdue/component'
import { ptNotificationStateChanged } from './components/pt-notification-state-changed/component'
import { ptNotificationTaskAssigneeWasAdded } from './components/pt-notification-task-assignee-was-added/component'
import { ptNotificationTaskDeadlineWasUpdated } from './components/pt-notification-task-deadline-was-updated/component'
import { ptNotificationTaskNoteWasCreated } from './components/pt-notification-task-note-was-created/component'
import { ptNotificationTaskStateWasUpdated } from './components/pt-notification-task-state-was-updated/component'
import { ptNotificationTaskUploadWasCreated } from './components/pt-notification-task-upload-was-created/component'
import { ptNotificationsModule } from './components/pt-notifications'
import { ptNotificationsFilterHeader } from './components/pt-notifications-filter-header/component'
import { ptNotificationsFilterPanel } from './components/pt-notifications-filter-panel/component'
import { ptNotificationsList } from './components/pt-notifications-list/component'

export const NotificationsModule = angular
  .module('notifications', ['ngAnimate', ptNotificationModule, ptNotificationsModule])
  .factory('NotificationsData', NotificationsData)
  .factory('NotificationsModel', NotificationsModel)
  .component('ptNotificationStateChanged', ptNotificationStateChanged)
  .component('ptNotificationRecordRetirementOverdue', ptNotificationRecordRetirementOverdue)
  .component('ptNotificationAccountRecordsRetirementOverdue', ptNotificationAccountRecordsRetirementOverdue)
  .component('ptNotificationTaskAssigneeWasAdded', ptNotificationTaskAssigneeWasAdded)
  .component('ptNotificationTaskDeadlineWasUpdated', ptNotificationTaskDeadlineWasUpdated)
  .component('ptNotificationTaskNoteWasCreated', ptNotificationTaskNoteWasCreated)
  .component('ptNotificationTaskStateWasUpdated', ptNotificationTaskStateWasUpdated)
  .component('ptNotificationTaskUploadWasCreated', ptNotificationTaskUploadWasCreated)
  .component('ptNotificationsFilterHeader', ptNotificationsFilterHeader)
  .component('ptNotificationsFilterPanel', ptNotificationsFilterPanel)
  .component('ptNotificationsList', ptNotificationsList).name
