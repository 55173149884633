import {SSOModelAuthenticate} from "AppModules/sso/services/models";

export function ptInvitationsPublicRoute($stateProvider) {
  'ngInject'

  $stateProvider.state('invitations', {
    parent: 'layoutFull',
    url: '/invitations?email&code&invitation_id',
    params: {
      skipAuth: null
    },
    views: {
      content: {
        component: 'ptInvitationsPublic'
      }
    },
    resolve: {
      invitations: resolveInvitations,
      ssoProviders: resolveSSOProviders,
      ssoProvidersAuthenticate: resolveSSOProvidersForAuthenticate
    },
    onEnter: onInvitationsEnter
  })

  function onInvitationsEnter(gettextCatalog, page) {
    'ngInject'

    /// Page Title
    page.setTitle(gettextCatalog.getString('Your Invitations'))
  }

  function resolveInvitations(invitationsForPublic, $transition$) {
    'ngInject'

    return invitationsForPublic.getAll($transition$.params().email, $transition$.params().code)
  }

  function resolveSSOProviders(SSOProvidersData) {
    'ngInject'

    return SSOProvidersData.getAll()
  }

  function resolveSSOProvidersForAuthenticate(SSOProvidersData) {
    'ngInject'

    return SSOProvidersData.getAllForAuthenticate()
  }
}
