export function ptWeb3Routes($stateProvider) {
  'ngInject'

  $stateProvider.state('analytics', {
    url: '/accounts/{accountId:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}/analytics'
  })

  $stateProvider.state('signup', {
    url: '/signup?referrer&firstname&lastname&email&company&telephone&name&planType&demo',
    data: { secure: false }
  })
}
